<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div v-if="provinces" class="toolbar_items">
          <span class="dropdown-label">Provinsi </span>
          <v-select
            v-if="
              role === ROLES.PublicHealthService_Regency ||
              role === ROLES.PublicHealthService_Province
            "
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            disabled
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
          <v-select
            v-else
            v-model="selected_province_id"
            placeholder="Provinsi"
            dense
            item-text="provinsi_name"
            item-value="provinsi_id"
            :items="provinces"
            hide-details="auto"
            outlined
            class="mt-1"
            @change="getCities"
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Kabupaten/Kota</span>
          <v-select
            v-if="cities"
            v-model="selected_regency_id"
            placeholder="Kabupaten/Kota"
            dense
            :disabled="role === ROLES.PublicHealthService_Regency"
            item-text="kabupaten_name"
            item-value="kabupatenKota_id"
            :items="cities"
            hide-details="auto"
            outlined
            class="mt-1"
          />
          <v-select
            v-else
            placeholder="Kabupaten/Kota"
            dense
            disabled
            hide-details="auto"
            outlined
          />
        </div>
        <div class="toolbar_items">
          <span class="dropdown-label">Tahun - Bulan</span>
          <v-menu
            ref="menu"
            v-model="menu_date"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                append-icon="mdi-calendar"
                placeholder="--- ------"
                readonly
                outlined
                dense
                hide-details
                v-bind="attrs"
                class="mt-1"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              color="primary"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="align-self-end">
          <v-btn
            class="mx-2 rounded-lg custom_shadow"
            color="primary"
            height="40px"
            dark
            min-width="10px"
            @click="fetchReportsWithReset"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div style="width: 100%">
        <v-row class="px-4 pt-8" style="width: 550px">
          <div style="width: 310px" class="mr-1">
            <v-text-field
              v-model="search_query"
              flat
              label="Cari Fasyankes"
              class="custom_field"
              background-color="#F1FBFC"
              solo
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              color="blue"
              @keydown="fetchReports"
            />
          </div>
          <div style="width: 42px" class="ml-1 mr-1">
            <v-menu
              v-model="menu_fasyankes_type"
              :close-on-content-click="false"
              :nudge-width="170"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="d-flex"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <v-icon class="mx-auto"> mdi-filter-outline </v-icon>
                </v-card>
              </template>
              <v-card class="pa-2 popup_radius" outlined elevation="0">
                <p class="px-2 mt-1 color_txt">Filter Fasyankes</p>
                <v-radio-group
                  v-model="selected_fasyankes_type"
                  dense
                  color="info"
                  hide-details="auto"
                >
                  <v-radio label="Semua Fasyankes" value="" />
                  <v-radio label="Rumah Sakit" value="1" />
                  <v-radio label="Puskemas" value="2" />
                </v-radio-group>
                <v-card-actions>
                  <v-spacer />

                  <v-btn
                    class="mt-4 txt-capitalize"
                    color="primary"
                    dark
                    depressed
                    width="100px"
                    @click="fetchReports"
                  >
                    done
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div style="width: 141px" class="ml-1">
            <v-menu
              v-model="menu_limit"
              bottom
              :nudge-bottom="55"
              elevation="0"
            >
              <template #activator="{ on, attrs }">
                <v-card
                  class="pa-3 d-flex align-center"
                  outlined
                  v-bind="attrs"
                  height="100%"
                  v-on="on"
                >
                  <p class="mx-auto ma-0 color_txt">Show : {{ limit }}</p>
                  <v-icon class="mx-auto"> mdi-chevron-down </v-icon>
                </v-card>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="onUpdateLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="onUpdateLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="onUpdateLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </div>

      <v-skeleton-loader v-if="is_loading" type="table-tbody" />

      <div v-if="!is_loading && environmental_health_key_report" class="my-5">
        <v-data-table
          :headers="headers"
          :items="environmental_health_key_report.data"
          :item-key="`environmental_health_key_report_${environmental_health_key_report.id}`"
          class="elevation-0 color_txt"
          :items-per-page="limit"
          hide-default-footer
          :item-class="getRowClasses"
        >
          <template #[`item.report_status`]="{ item }">
            <Dot :color="$getStatusColorHex(item.report_status)" />
          </template>
          <template #[`item.status_criteria`]="{ item }">
            <CriteriaBadge :valid="item.status_criteria" />
          </template>
          <template #[`item.actions`]="{ item }">
            <div v-if="item.report_id !== null">
              <v-btn
                small
                depressed
                :color="item.disable ? '#64C9DA' : '#59B5CC'"
                class="rounded-lg text-capitalize"
                min-width="10px"
                dark
                @click="toDetail(item)"
              >
                <v-icon class="mr-3"> mdi-magnify </v-icon> Lihat Isian Data
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="d-flex justify-space-between pa-3">
        <div class="bottom-bar">
          <div class="px-2 mt-7">
            <ReportStatusLegend />
          </div>
          <div class="service-indicators mt-4 px-2">
            <h5>Keterangan Tingkat Layanan Domain</h5>
            <div>0 : Tidak Ada Layanan, 1 : Layanan Terbatas,</div>
            <div>2 : layanan dasar dan 3 : layanan paripurna.</div>
          </div>
        </div>
        <div class="bottom-bar paginate-buttons">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('prev')"
            >
              <v-icon small> mdi-arrow-left </v-icon>
            </v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">
                {{ page }} of
                {{ environmental_health_key_report.last_page }}
              </p>
            </div>
            <v-btn
              small
              min-width="10px"
              color="#00b4cc"
              dark
              class="pa-2 custom_shadow"
              @click="pagination('next')"
            >
              <v-icon small> mdi-arrow-right </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="table-actions">
          <v-btn
            rounded
            dark
            color="#fff"
            class="text-capitalize"
            style="color: black"
            @click="show_modal_summary = true"
          >
            Ringkasan Data
          </v-btn>
          <v-btn
            rounded
            dark
            color="primary"
            class="text-capitalize"
            @click="modalDialogRekapData = true"
          >
            Rekap Data
          </v-btn>
          <v-btn
            rounded
            dark
            color="primary"
            class="text-capitalize"
            @click="modalDialogUnduhData = true"
          >
            Unduh Data
          </v-btn>
        </div>
      </div>
    </v-card>
    <div id="angker" />
    <v-card v-if="view_detail" class="mt-5 rounded-max custom_card pa-5">
      <IsianKesling
        :id="selected_report_id"
        :isian-data="isianData"
        :profile="fasyankes_profile"
        :created="created"
        :report_status="selected_report_status"
        :report="selected_report"
      />
    </v-card>
    <RingkasanDataKeslingKunci
      :show="show_modal_summary"
      :date="date"
      :province_id="selected_province_id"
      :regency_id="selected_regency_id"
      @close="show_modal_summary = false"
    />
    <RekapData
      :modal-dialog-rekap-data="modalDialogRekapData"
      :form-type="'environmental-health-key'"
      @close="modalDialogRekapData = false"
    />
    <UnduhData
      :modal-dialog-unduh-data="modalDialogUnduhData"
      :tipe-form="typeKesling"
      :tipe-date="'month'"
      @close="modalDialogUnduhData = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import IsianKesling from "../../../../components/Data/Detail/isianFormulir/isianKesling.vue";
import RekapData from "../../../../components/Data/RekapData.vue";
import RingkasanDataKeslingKunci from "../../../../components/Data/RingkasanDataKeslingKunci.vue";
import UnduhData from "../../../../components/Data/UnduhData.vue";
import ReportStatusLegend from "../../../../components/Form/Legend/ReportStatus/index.vue";
import CriteriaBadge from "../../../../components/icons/CriteriaBadge.vue";
import Dot from "../../../../components/icons/Dot.vue";
import { ROLES } from "../../../../constants/constant";

export default {
  name: "TableTabEnvironmentalHealthKey",
  components: {
    Dot,
    CriteriaBadge,
    IsianKesling,
    UnduhData,
    RekapData,
    RingkasanDataKeslingKunci,
    ReportStatusLegend,
  },
  data() {
    return {
      ROLES,
      is_loading: false,
      users: [],
      provinces: [],
      cities: [],
      selected_province_id: null,
      selected_regency_id: null,
      date: this.$date().format("YYYY-MM"),
      search_query: "",
      page: 1,
      limit: 10,
      menu_date: false,
      menu_limit: false,
      menu_fasyankes_type: false,
      selected_fasyankes_type: "",
      headers: [
        {
          text: "Fasyankes",
          align: "start",
          value: "fasyankes_name",
          width: "300px",
        },
        {
          text: "Status",
          align: "center",
          value: "report_status",
        },
        {
          text: "Air",
          align: "center",
          value: "status_water_no",
        },
        {
          text: "Sanitasi",
          align: "center",
          value: "status_sanitation_no",
        },
        {
          text: "Higiene",
          align: "center",
          value: "status_higiene_no",
        },
        {
          text: "Limbah",
          align: "center",
          value: "status_waste_no",
        },
        {
          text: "Kebersihan",
          align: "center",
          value: "status_cleaning_no",
        },
        {
          text: "Kriteria",
          align: "center",
          value: "status_criteria",
          width: "200px",
        },
        { text: "Aksi", align: "center", sortable: false, value: "actions" },
      ],
      fasyankes_profile: null,
      view_detail: false,
      viewDetailKesling: false,
      isianData: null,
      show_modal_summary: false,
      modalDialogRekapData: false,
      modalDialogUnduhData: false,
      selected_report: null,
      selected_report_id: null,
      selected_report_status: null,
      typeKesling: "kesehatan lingkungan pertanyaan kunci",
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      environmental_health_key_report: (state) =>
        state.report.environmental_health_key_report,
    }),
  },
  watch: {
    selected_province_id() {
      if (
        this.selected_province_id &&
        this.selected_province_id !== "" &&
        this.role !== ROLES.PublicHealthService_Regency
      ) {
        this.selected_regency_id = null;
      }
    },
  },

  async mounted() {
    await this.getProvinces();
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }
    if (this.profile.access_right === ROLES.PublicHealthService_Regency) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_regency_id = parseInt(this.profile.kota);
      this.selected_role = ROLES.PublicHealthService_Regency;
      await this.getCities();
    }
    if (this.profile.access_right === ROLES.PublicHealthService_Province) {
      this.selected_province_id = parseInt(this.profile.provinsi);
      this.selected_role = ROLES.PublicHealthService_Regency;
    }
    if (this.selected_province_id) {
      this.getCities();
    }
    this.fetchReports();
  },
  methods: {

    getProvinces() {
      this.$store.dispatch("region/getProvinces").then((data) => {
        this.provinces = data.data;
        const default_province = {
          provinsi_id: "",
          provinsi_name: "SEMUA (NASIONAL)",
        };
        this.provinces.unshift(default_province);
      });
    },
    getCities() {
      if (!this.selected_province_id || this.selected_province_id === "") {
        this.selected_regency_id = null;
        this.cities = [];
        return;
      }
      const payload = { provinsi_id: this.selected_province_id };
      this.$store.dispatch("region/getCities", payload).then((data) => {
        let cities = data.data;
        
        if (cities?.length > 0) {
          const all_city = {
            kabupatenKota_id: "",
            kabupaten_name: "SEMUA (KABUPATEN/KOTA)",
          };
          cities?.unshift(all_city);
          
        }
        this.cities = cities;
      });
    },
    async fetchReports() {
      this.menu_limit = false;
      this.is_loading = true;
      const request_params = {
        page: this.page,
        limit: this.limit,
        search_query: this.search_query,
        date: this.date,
        province_id: this.selected_province_id,
        regency_id: this.selected_regency_id,
        fasyankes_type: this.selected_fasyankes_type,
      };
      const res = await this.$store.dispatch(
        "report/getEnvironmentalHealthKeyReport",
        request_params
      );
      this.is_loading = false;
    },
    async fetchReportsWithReset() {
      this.page = 1;
      this.limit = 10;
      this.search_query = "";

      this.fetchReports();
    },
    onUpdateLimit(limit) {
      this.menu_limit = false;
      this.limit = limit;
      this.fetchReports();
    },
    getRowClasses(item) {
      let idx = this.environmental_health_key_report.data.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue";
      }
    },
    pagination(type) {
      if (type === "prev") {
        if (this.page > 1) {
          this.page--;
          this.fetchReports();
        }
      } else if (type === "next") {
        if (this.page < this.environmental_health_key_report.last_page) {
          this.page++;
          this.fetchReports();
        }
      }
    },
    toDetail(report) {
      this.$store.dispatch("report/getReportById", report.report_id);

      this.selected_report_id = report.report_id;
      this.selected_report_status = report.report_status;
      this.created = report.report_createTime;
      this.view_detail = true;

      this.getIsian(report);
      this.getProfile(report);
      setTimeout(() => {
        this.$vuetify.goTo("#angker");
      }, 500);
    },
    getIsian(item) {
      let body = {
        report_id: item.report_id,
      };

      this.$store.dispatch("formulir/getPertanyaanKunci", body).then((res) => {
        this.isianData = res.data.Kesehatan_Lingkungan;
      });
    },
    getProfile(item) {
      let id = item.report_id;
      this.$store.dispatch("data/profileHeader", id).then((data) => {
        if (data.header[0].nama_rs) {
          data["0"]["name"] = data.header[0].nama_rs;
        } else {
          data["0"]["name"] = data.header[0].nama_puskesmas;
        }
        this.fasyankes_profile = data["0"];
      });
    },
  },
};
</script>

<style scoped>
* {
  font-family: "Montserrat";
}

.v-btn__content {
  min-width: none !important;
  width: 30px !important;
}

.dropdown-label {
  font-weight: 500;
}

.paginate-buttons {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
}

.round_paginate {
  height: max-content;
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}

.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}

.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}

.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}

.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}

.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}

.bottom-bar {
  width: calc(100% / 3);
}

.toolbar_items {
  width: calc(90% / 4);
  padding-right: 10px;
}

.table-actions {
  display: flex;
  gap: 10px;
}

.service-indicators h5 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.service-indicators {
  margin-top: 20px;
  font-size: 12px;
}
</style>
