<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <blue-circle class="mr-2" />
      <h3 class="color_default">
        Tabel
      </h3>
    </div>

    <v-tabs
      v-model="selected_tab"
      background-color="#cdf0f7"
      hide-slider
      class="pb-2"
    >
      <v-tab
        href="#enviromental-health-key"
        value="enviromental-health-key"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Kesling Kunci
        </p>
      </v-tab>
      <v-tab
        href="#waste"
        value="waste"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Kelola Limbah
        </p>
      </v-tab>
      
      <v-tab
        v-if="role === 'Dinkes Kota'"
        href="#login-access"
        value="login-access"
        class="text-capitalize mr-2 header_data"
        style="display: none;"
        active-class="active_tab"
      >
        <p class="ma-0">
          Dinkes Last Login
        </p>
      </v-tab>
      <v-tab
        v-else
        href="#login-access"
        value="login-access"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Dinkes Last Login
        </p>
      </v-tab>
      <v-tab
        href="#montly-report"
        value="montly-report"
        class="text-capitalize mr-2 header_data"
        active-class="active_tab"
      >
        <p class="ma-0">
          Fasyankes Last Input
        </p>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="selected_tab"
      style="background: none"
    >
      <v-tab-item value="enviromental-health-key">
        <TableTabEnvironmentalHealthKey />
      </v-tab-item>
      <v-tab-item value="waste">
        <TableTabWaste />
        <div style="height: 100px" />
      </v-tab-item>
      <v-tab-item value="login-access">
        <TableTabLoginAccesses />
      </v-tab-item>
      <v-tab-item value="montly-report">
        <TableTabMonthlyReport />
      </v-tab-item>
    </v-tabs-items>
    <div class="pa-2" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BlueCircle from "../../components/General/BlueCircle";
import TableTabEnvironmentalHealthKey from "./Tab/EnviromentalHealthKey/index.vue";
import TableTabLoginAccesses from "./Tab/LoginAccesses.vue";
import TableTabMonthlyReport from "./Tab/MonthlyReport.vue";
import TableTabWaste from "./Tab/Waste/index.vue";

export default {
  name: "ListData",
  components: {
    BlueCircle,
    TableTabEnvironmentalHealthKey,
    TableTabLoginAccesses,
    TableTabMonthlyReport,
    TableTabWaste,
  },
   data() {
    return {
      tab: "enviromental-health-key",
      updateLoginStatus: false,
      loginActive: true,
      selected_tab: null,
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.all_user,
      role: (state) => state.role,
    }),
  },

  watch: {
    selected_tab() {
      this.$store.dispatch(
        "globalUI/setPageTableSelectedTab",
        this.selected_tab
      );
    },
  },

  methods: {
    switchStatusLogin() {
      this.updateLoginStatus = true;
    },
  },
};
</script>

<style scoped>
.padd_content {
  padding: 40px 50px;
}
/* ListUser */
.header_data {
  color: #00b4cc !important;
  background: #fff;
  width: calc(100% / 5);
  border-radius: 13px 13px 0 0;
}
.active_tab {
  background: #00b4cc;
  color: #fff !important;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
</style>